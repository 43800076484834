import jquery from 'jquery';
import {classFunction} from "./class/classFunction";
declare var sendgtag: any;

//var api_url = "https://dict.foreston.jp/p/index.php?s=api_get_list_category&callback=categoryList";
var api_url = "https://dict.himhong.com/p/index.php?s=api_get_list_category&callback=categoryList";

var funcs:classFunction;



console.log(api_url);
$(window).on('load', function() {
    funcs = new classFunction();

    $.ajax({
        url: api_url,
        dataType: "jsonp",
    }).done(function(result) {
        // code here
        getCategoryList(result).then(()=>{
          sendgtag();
        });
    });

});
function getCategoryList (result:any): Promise<void> {
  return new Promise((resolve, reject) => {
    var data = result["data"]["categories"];
    var categoryListHtml = "";
    //console.log(data);
    for (var i in data) {
        categoryListHtml += '<li><a href="/list/?id='+data[i]["id"]+'"><img style="background-image:url('+data[i]["imagesrc"]+');" src="/images/spacer.png" alt="'+data[i]["name"]+'">'+data[i]["name"]+'</a></li>';
    }
    $("#category-list").html(categoryListHtml);
    resolve();
  });
}